*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: clip;
}

html body {
  padding: 0;
  margin: 0;
  overflow-x: inherit;
  height: 100%;
  width: 100vw;
}

.btn-dark-light {
  --bs-btn-color: #fff;
  --bs-btn-bg: #404F65;
  --bs-btn-border-color: #404F65;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #364356;
  --bs-btn-hover-border-color: #333f51;
  --bs-btn-focus-shadow-rgb: 93, 105, 124;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333f51;
  --bs-btn-active-border-color: #303b4c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #404F65;
  --bs-btn-disabled-border-color: #404F65;
}

.team-round-image {
  height: 170px;
  width: 170px;
  border-radius: 50%;
}

.input-lg {
  padding: .5rem 1rem; /* Same padding as btn-lg */
  font-size: 1.25rem; /* Same font-size as btn-lg */
  line-height: 1.5; /* Standard line height for readability */
}

.select-lg {
  padding: .5rem 1rem; /* Padding with enough space for text */
  font-size: 1.25rem; /* Larger font size for better readability */
  height: auto; /* Adjust height to fit content */
  line-height: 1.5; /* Standard line height */
}

/* Styles for dropdown items */
.dropdown-item-custom {
  font-size: 1.1rem;
  padding: 10px 15px;
}

.dropdown-item-custom:hover {
  color: #364356; /* or any other color that is visible on dark background */
}

/* Additional styling for the dropdown menu */
.dropdown-menu {
  border-radius: 0.5rem; /* Rounded corners */
  color: #fff; /* White text */
}

.my-toast {
  --bs-toast-color: #fff;
  --bs-toast-bg: #2AD167;
  --bs-toast-border-color: #2AD167;
  background-color: var(--bs-toast-bg);
  color: var(--bs-toast-color);
  border-color: var(--bs-toast-border-color);
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  min-width: 250px;
  z-index: 9999;
}

.my-toast .toast-body {
  font-size: 1.1rem; /* Larger text */
}

.my-toast .btn-close {
  color: var(--bs-toast-color);
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: white;
}
